import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { groupBy } from 'lodash';
import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import notify from 'notify';
import useOpenContractBuilder from 'hooks/useOpenContractBuilder';
import {
  deleteDocument as deleteDocumentRequest,
  deleteMultipleDocuments,
  downloadDocument,
  downloadDocumentsArchive,
  getDocuments,
  getExternalDocuments,
  shareDocuments,
  executeOfferRequest
} from 'api/documents';
import { getTemplates } from 'api/templates';
import { tryGetFirstError } from 'utils/requests';
import BuyProcess from 'views/BuyProcess';
import SellProcess from 'views/SellProcess';
import { Button, Checkbox, ModalConfirm, ModelExecuteOffer } from 'components';
import WorkspaceContext from '../WorkspaceContext';
import DocumentsTableItem from './DocumentsTableItem';
import UploadFileMenu from './UploadFileMenu';
import CreateNewMenu from './CreateNewMenu';
// import ShareDocumentModal from './ShareDocumentModal';
import ShareDocumentForm from './ShareDocumentForm';

const getDocumentsByCategory = (arr: WorkspaceDocument[], categoryName: string) => {
  return arr.filter(doc => doc.category === categoryName);
};

const WorkspaceDocumentsTab = () => {
  const { listing, members, emailDocumentsData, setEmailDocumentsData, emailDocumentsDataStep, setEmailDocumentsDataStep, isLinkedListing, setLinkedListing } = useContext(WorkspaceContext);
  const [documents, setDocuments] = useState<WorkspaceDocument[]>([]);
  const [userSelectedDocuments, setUserSelectedDocuments] = useState(false);
  const [templates, setTemplates] = useState<FormTemplate[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [selectedDocumentCategories, setSelectedDocumentCategories] = useState<string[]>([]);
  const [uploadMenuOpen, setUploadMenuOpen] = useState(false);
  const [createNewMenuOpen, setCreateNewMenuOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [disableContractBuilderButton] = useOpenContractBuilder();
  const [deleteDocumentData, setDeleteDocumentData] = useState<number[]>();
  const [executeOfferModalIsOpen, setExecuteOfferModalIsOpen] = useState<boolean>(false);
  const [executeOfferDocument, setExecuteOfferDocument] = useState<WorkspaceDocument>([]);
  const [shareDocumentData, setShareDocumentData] = useState<number[]>();
  const [disableActions, setDisableActions] = useState(false);
  
  
  const [sellProcessData, setSellProcessData] = useState<{
    listingId: string;
    sellProcessId?: string;
  }>();
  const [offerId, setOfferId] = useState<string | undefined>();
  const listingId = listing?.listingId;
  const [showAttorneyDropdown, setShowAttorneyDropdown] = useState(false);
  const sharedDocuments = useMemo(() => {
    return documents.filter(document => shareDocumentData?.includes(document.id));
  }, [shareDocumentData, documents]);

  const fetchDocuments = useCallback(async listingId => {
    try {
      return await getDocuments(listingId);
    } catch (err) {
      notify(err.response || err.message);
    }
  }, []);

  // make a separate call as it might delay the retrieval process of documents (as a call is made to XP)
  const fetchExternalDocuments = useCallback(async listingId => {
    try {
      var extDocs = await getExternalDocuments(listingId);
      console.log(extDocs);
      setLinkedListing(extDocs.is_linked);
      return extDocs.documents;
    } catch (err) {
      notify(err.response || err.message);
    }
  }, []);

  const fetchTemplates = useCallback(async () => {
    try {
      const data = await getTemplates();
      setTemplates(data.filter(item => item.type === 'workspace'));
    } catch (err) {
      notify(err.response || err.message);
    }
  }, []);

  const loadAllDocuments = useCallback(
    async listingId => {
      const [result1 = [], result2 = []] = await Promise.all([
        fetchDocuments(listingId),
        fetchExternalDocuments(listingId)
      ]);
      setDocuments([...result1, ...result2]);
    },
    [fetchDocuments, fetchExternalDocuments]
  );

  

  useEffect(() => {
    setLinkedListing(false);
    
    loadAllDocuments(listingId);
  }, [listingId, loadAllDocuments]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    if(emailDocumentsData?.document_ids?.length > 0 && selectedDocuments.length === 0){
      if(selectedDocuments.length === 0 && userSelectedDocuments === false){
        setSelectedDocuments([...selectedDocuments, ...emailDocumentsData?.document_ids]);
      }else if(selectedDocuments.length === 0 && userSelectedDocuments === true){
        setUserSelectedDocuments(true);
        setEmailDocumentsData([]);
      }
    }
  }, [selectedDocuments]);

  const handleFileUploadSuccess = () => {
    setUploadMenuOpen(false);
    loadAllDocuments(listingId);
  };

  const handleDocumentSelectionChange = (e, id) => {
    const { checked } = e.target;
    const documentsToSelect = checked
      ? [...selectedDocuments, id]
      : selectedDocuments.filter(item => item !== id);
    const selectedDocument = documents.find(document => document.id === id);
    const documentsInCategory = documents
      .filter(document => document.category === selectedDocument?.category)
      .map(document => document.id);

    setSelectedDocuments(documentsToSelect);
    setUserSelectedDocuments(true);

    if (!checked) {
      return setSelectedDocumentCategories(items =>
        items.filter(item => item !== selectedDocument?.category)
      );
    }

    if (documentsInCategory.every(item => documentsToSelect.includes(item))) {
      setSelectedDocumentCategories(items => [...items, String(selectedDocument?.category)]);
    }
  };

  const handleDocumentsGroupSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const checkedDocuments = documents.filter(document => selectedDocuments.includes(document.id));

    if (checked) {
      setSelectedDocumentCategories(items => [...items, value]);
      return setSelectedDocuments(items => [
        ...items,
        ...documents.filter(document => document.category === value).map(document => document.id)
      ]);
    }

    setSelectedDocumentCategories(items => items.filter(item => item !== value));
    setSelectedDocuments(
      checkedDocuments.filter(document => document.category !== value).map(document => document.id)
    );
  };

  const deleteSelectedDocuments = async () => {
    try {
      await deleteMultipleDocuments(selectedDocuments);
      setDocuments(items => items.filter(item => !selectedDocuments.includes(item.id)));
      setSelectedDocuments([]);
      setSelectedDocumentCategories([]);
      setDeleteDocumentData(undefined);
    } catch (err) {
      notify(err.response || err.message);
    }
  };

  const deleteDocument = async () => {
    if (!deleteDocumentData) return;
    setDisableActions(true);
    try {
      const id = deleteDocumentData[0];
      setDeleteDocumentData(undefined);
      await deleteDocumentRequest(String(id));
      setDocuments(documents => documents.filter(item => item.id !== id));
      setSelectedDocuments(documents => documents.filter(item => item !== id));
      notify('Document deleted');
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setDisableActions(false);
  };

  const downloadSelectedDocuments = async () => {
    setIsDownloading(true);
    setDisableActions(true);
    try {
      const a = document.createElement('a');
      if (selectedDocuments.length > 1) {
        const archive = await downloadDocumentsArchive(selectedDocuments);
        a.href = archive;
        a.download = 'documents.zip';
      } else {
        const document = await downloadDocument(selectedDocuments[0]);
        a.href = document.url;
        a.download = document.name;
      }
      a.target = '_blank';
      a.rel = 'noreferrer';
      a.click();
      setSelectedDocuments([]);
      setSelectedDocumentCategories([]);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    setIsDownloading(false);
    setDisableActions(false);
  };

  const shareSelectedDocuments = async (values: {
    ids: number[];
    customEmails?: string[];
    message?: string;
    zipFormat?: boolean;
  }) => {
    setDisableActions(true);
    try {
      if (!values.ids.length) throw Error('No documents selected');
      if (!values.customEmails?.length) throw Error('No emails selected');
      await shareDocuments(values);
      notify('Message has been sent successfully');
    } catch (err) {
      notify(tryGetFirstError(err));
    } finally {
      setEmailDocumentsData([]);
      setEmailDocumentsDataStep(0);
      setShareDocumentData(undefined);
      setSelectedDocuments([]);
      setSelectedDocumentCategories([]);
      setDisableActions(false);
    }
  };

  useEffect(() => {
    if (documents.length === 0) setSelectedDocuments([]);
  }, [documents.length]);
  const { fetchListing } = useContext(WorkspaceContext);
  const layout = useLayout();
  const unclassifiedDocuments = useMemo(() => documents.filter(document => !document.category), [
    documents
  ]);

  const categories = useMemo(() => groupBy(documents, 'categoryOrder'), [documents]);
  const orderedCategories = Object.keys(categories).map(cat => categories[cat][0]?.category);

  const buttonGroup = useMemo(
    () => (
      <div className="btn-group">
        {listing?.status !== 'Closed' && (
          <Button
            secondary
            onClick={() => setCreateNewMenuOpen(true)}
            disabled={disableContractBuilderButton}>
            Create new
          </Button>
        )}
        <Button className="upload" onClick={() => setUploadMenuOpen(true)}>
          Upload
        </Button>
      </div>
    ),
    [disableContractBuilderButton, setCreateNewMenuOpen, setUploadMenuOpen]
  );

  const handleListingAgreementSubmit = () => {
    setSellProcessData(undefined);
    fetchListing();
    handleCreateNewDocumentSubmit(true);
  };

  const handleOfferSubmit = () => {
    setOfferId(undefined);
    handleCreateNewDocumentSubmit(true);
  };

  const handleSendMessageTemplate = () => {
    setShareDocumentData(selectedDocuments);
    setEmailDocumentsDataStep(2);
  }

  const handleCancelMessageTemplate = () => {
    setEmailDocumentsData([]);
    setShareDocumentData(undefined);
    setSelectedDocuments([]);
    setSelectedDocumentCategories([]);
    setDisableActions(false);
    setEmailDocumentsDataStep(0);
  }

  const handleCreateNewDocumentSubmit = async (fetch?: boolean) => {
    setCreateNewMenuOpen(false);
    if (fetch) {
      const response = await fetchDocuments(listingId);

      if (response) {
        setDocuments(value => [...response, ...value.filter(item => item.isExternal)]);
      }
    }
  };

  const openBuyOrSellProcess = (document: WorkspaceDocument) => {
    const docName = document.docname;
    if (docName === 'Listing Agreement') {
      return setSellProcessData({
        listingId: listing?.listingId!,
        sellProcessId: listing?.sellProcessId
      });
    }
    if (document.buyProcessId) setOfferId(document.buyProcessId);
  };

  const executeOfferProcess = async (document: WorkspaceDocument) => {  
    setExecuteOfferDocument(document);
    setExecuteOfferModalIsOpen(true);
  };

  if (!listing) return null;
  var offerCountCounter = 1;
  return (
    
    <StyledWorkspaceDocumentsTab>
      {emailDocumentsData.length !== 0 && emailDocumentsDataStep > 0 && ( 
        <StyledMessageHeader>
          {emailDocumentsDataStep === 1 && ("Step 1: select any documents (optional)")}
          {emailDocumentsDataStep === 2 && ("Step 2: Select recipients and type message")}
        </StyledMessageHeader>
      )}
      {!uploadMenuOpen && !createNewMenuOpen && !shareDocumentData?.length && (
        <>
          {layout === 'mobile' && buttonGroup}
          <div className="table-wrapper">
            <table className="documents-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th></th>
                  <th>{layout !== 'mobile' && buttonGroup}</th>
                </tr>
              </thead>
              {orderedCategories.map(category => (
                <React.Fragment key={category}>
                  <thead>
                    <tr className="document-categories">
                      <th>
                        <Checkbox
                          value={category}
                          checked={selectedDocumentCategories.includes(category)}
                          onChange={handleDocumentsGroupSelectionChange}
                          label={category}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getDocumentsByCategory(documents, category)
                      .map(document => (
                        <DocumentsTableItem
                          offerCount={document.docname==='Offer' ? offerCountCounter++ : offerCountCounter}
                          key={document.id}
                          data={document}
                          selected={selectedDocuments.includes(document.id)}
                          onDeleteClick={document => setDeleteDocumentData([document.id])}
                          onSelectChange={handleDocumentSelectionChange}
                          onShareClick={document => setShareDocumentData([document.id])}
                          onEditClick={() => openBuyOrSellProcess(document)}
                          onExecuteClick={document => executeOfferProcess(document)}
                        />
                      ))}
                  </tbody>
                </React.Fragment>
              ))}
              {unclassifiedDocuments.length > 0 && (
                <>
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                          value=""
                          checked={selectedDocumentCategories.includes('')}
                          onChange={handleDocumentsGroupSelectionChange}
                          label="Other"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {unclassifiedDocuments.map(document => (
                      <DocumentsTableItem
                        key={document.id}
                        data={document}
                        selected={selectedDocuments.includes(document.id)}
                        onDeleteClick={document => setDeleteDocumentData([document.id])}
                        onSelectChange={handleDocumentSelectionChange}
                        onShareClick={document => setShareDocumentData([document.id])}
                      />
                    ))}
                  </tbody>
                </>
              )}
            </table>
            {documents.length === 0 && <div className="not-found-message">No documents yet.</div>}
          </div>
          {selectedDocuments.length !== 0 && emailDocumentsData.length === 0 && (
            <div className="selected-documents-buttons">
              <Button secondary onClick={() => setDeleteDocumentData(selectedDocuments)}>
                Delete
              </Button>
              <Button secondary onClick={() => setShareDocumentData(selectedDocuments)}>
                Share
              </Button>
              <Button onClick={downloadSelectedDocuments} disabled={isDownloading}>
                {isDownloading ? 'Downloading...' : 'Download'}
              </Button>
            </div>
          )}
          {selectedDocuments.length !== 0 && emailDocumentsData.length !== 0 && (
            <div className="automated-selected-documents-buttons">
              <Button onClick={() => handleSendMessageTemplate()}>
                Next
              </Button>
              <Button secondary onClick={() => handleCancelMessageTemplate(selectedDocuments)}>
                Cancel
              </Button>
            </div>
          )}
        </>
      )}
      {createNewMenuOpen && (
        <CreateNewMenu
          templates={templates}
          listing={listing}
          onSuccess={handleCreateNewDocumentSubmit}
          onClose={() => setCreateNewMenuOpen(false)}
          onClickListingAgreement={() =>
            setSellProcessData({
              listingId: listing.id
            })
          }
        />
      )}
      {uploadMenuOpen && (
        <UploadFileMenu
          listingId={listing.id}
          onSuccess={handleFileUploadSuccess}
          onCancel={() => setUploadMenuOpen(false)}
        />
      )}
      {shareDocumentData?.length && (
        <ShareDocumentForm
          documents={sharedDocuments}
          onCancel={() => { setShareDocumentData(undefined); 
                            setEmailDocumentsData([]);
                            setSelectedDocuments([]);
                            setSelectedDocumentCategories([]);
                            setDisableActions(false); 
                            setEmailDocumentsDataStep(0);
                          }
                    }
          onSave={shareSelectedDocuments}
          disableActions={disableActions}
        />
      )}
      <StyledDeletionModal
        modalTitle="Delete document"
        open={Boolean(deleteDocumentData)}
        onClose={() => setDeleteDocumentData(undefined)}
        onSubmit={() => {
          if (deleteDocumentData!.length > 1) deleteSelectedDocuments();
          else deleteDocument();
        }}>
        Are you sure you wan to delete{' '}
        {deleteDocumentData?.length ? 'these documents' : 'this document'}? This action can't be
        undone
      </StyledDeletionModal>

      <StyledExecuteOfferModal
        modalTitle="Offer Document"
        open={Boolean(executeOfferModalIsOpen)}
        showAttorneyDropdown={Boolean(showAttorneyDropdown)}
        setShowAttorneyDropdown={(value) => {setShowAttorneyDropdown(value)}}
        members={members}
        onClose={() => {setExecuteOfferModalIsOpen(false) 
                        setShowAttorneyDropdown(false)}
                      }
        onSubmit={ async (formValues) => {
          const success = await executeOfferRequest(executeOfferDocument,formValues.page, formValues.fillSellSideData, formValues.AttorneyId);
          if(success){
            documents.forEach(item => {
              if (item.id == executeOfferDocument.id) {
                item.documentStatus = 2;
              }
            });
            setExecuteOfferModalIsOpen(false);
            window.open(`/pdf-editor?id=${executeOfferDocument.id}`);
          }    
        }}
      >
      </StyledExecuteOfferModal>
      {sellProcessData && (
        <SellProcess
          open={Boolean(sellProcessData)}
          onClose={() => setSellProcessData(undefined)}
          onSuccess={handleListingAgreementSubmit}
          {...sellProcessData!}
        />
      )}
      <BuyProcess
        open={Boolean(offerId)}
        buyProcessId={offerId}
        listing={listing}
        openFromWorkspace={true}
        onClose={() => setOfferId(undefined)}
        onSave={handleOfferSubmit}
      />
    </StyledWorkspaceDocumentsTab>
  );
};

export default WorkspaceDocumentsTab;

const StyledMessageHeader = styled.p`
color: black;
font-size: 18px;
margin-bottom: 0;
`;

const StyledWorkspaceDocumentsTab = styled.div`
  margin-bottom: -24px;
  flex: 1;

  .table-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: auto;

    .documents-table {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      width: 100%;
      border-collapse:collapse;

      th {
        font-weight: normal;
        color: #8b8b8b;
        line-height: 16px;
        text-align: left;
        padding: 16px 0;
        .button + .button {
          margin-left: 10px;
        }
      }

      thead:first-of-type th {
        border-bottom: 1px solid ${props => props.theme.colors.seashell};

        &:last-of-type {
          text-align: end;
          width: 160px;
        }
      }
    }
  }

  .automated-selected-documents-buttons .secondary{margin-right:10px;}

  .document-categories label{font-weight:bold;}

  .documents-table .document-row:nth-of-type(even) {background: #f1f1f1;}

  .selected-documents-buttons {
    position: sticky;
    display: flex;
    align-items: center;
    bottom: -24px;
    height: 60px;
    margin: 0 -20px;
    padding: 8px 20px;
    background: #f8f8f8;

    .button:nth-of-type(1) {
      margin-right: auto;
    }
    .button:nth-of-type(2) {
      margin-right: 10px;
    }
  }

  .automated-selected-documents-buttons {
    position: sticky;
    display: flex;
    align-items: center;
    bottom: -24px;
    height: 60px;
    margin: 0 -20px;
    padding: 8px 20px;
    background: #f8f8f8;flex-direction: row-reverse;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .btn-group {
      margin: 16px 0;
      display: flex;
      .button {
        flex: 1;
      }
      .button + .button {
        margin-left: 8px;
      }
    }
  }
`;

const StyledDeletionModal = styled(ModalConfirm)`
  .modal-content {
    max-width: 400px;

    .doc-name {
      white-space: nowrap;
    }
  }
`;

const StyledExecuteOfferModal = styled(ModelExecuteOffer)`
  .modal-content {
    max-width: 400px;

    .doc-name {
      white-space: nowrap;
    }
  }
`;
